export const Drivers = [
    {
        name: "Max Verstappen",
        number: 1,
        team: "Red Bull Racing",
    },
    {
        name: "Sergio Perez",
        number: 11,
        team: "Red Bull Racing",
    },
    {
        name: "Charles Leclerc",
        number: 16,
        team: "Ferrari",
    },
    {
        name: "Carlos Sainz",
        number: 55,
        team: "Ferrari",
    },
    {
        name: "George Russell",
        number: 63,
        team: "Mercedes",
    },
    {
        name: "Lewis Hamilton",
        number: 44,
        team: "Mercedes",
    },
    {
        name: "Esteban Ocon",
        number: 31,
        team: "Alpine",
    },
    {
        name: "Pierre Gasly",
        number: 10,
        team: "Alpine",
    },
    {
        name: "Oscar Piastri",
        number: 81,
        team: "McLaren",
    },
    {
        name: "Lando Norris",
        number: 4,
        team: "McLaren",
    },
    {
        name: "Valtteri Bottas",
        number: 77,
        team: "Kick Sauber",
    },
    {
        name: "Zhou Guanyu",
        number: 24,
        team: "Kick Sauber",
    },
    {
        name: "Lance Stroll",
        number: 18,
        team: "Aston Martin",
    },
    {
        name: "Fernando Alonso",
        number: 14,
        team: "Aston Martin",
    },
    {
        name: "Kevin Magnussen",
        number: 20,
        team: "Haas",
    },
    {
        name: "Nico Hulkenberg",
        number: 27,
        team: "Haas",
    },
    {
        name: "Daniel Ricciardo",
        number: 3,
        team: "Racing Bulls",
    },
    {
        name: "Yuki Tsunoda",
        number: 22,
        team: "Racing Bulls",
    },
    {
        name: "Alexander Albon",
        number: 23,
        team: "Williams",
    },
    {
        name: "Logan Sargeant",
        number: 2,
        team: "Williams",
    },
]

export const Rookies = []