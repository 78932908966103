import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "./button";
import { colors } from "../utils/colors";

export const SaveButton = () => {
  const navigate = useNavigate();
  return (
    <div style={{ display: 'flex', width: 340}}>
      <div style={{ width: 167 }} />
      <Button
      style={{
        backgroundColor: colors.primaryRed,
        color: colors.white,
        width: "167px",
        marginBottom: "12px",
        marginTop: "12px",
      }}
      onClick={() => {
        navigate("/predictions");
      }}
    >
      Save
    </Button>
    </div>
  );
};
