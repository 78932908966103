import React, { useContext } from "react";
import styled from "styled-components";
import { CollapseView } from "../components/collapseView";
import { Nav } from "../components/nav";
import { PageContainer } from "../components/pageContainer";
import { F1Context } from "../context";

const HeaderContainer = styled.div`
  font-family: "Formula1";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
`;

const ScrollableView = styled.div`
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const RankingEntry = styled.div`
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  text-align: start;
`;

export const GroupDetailPage = () => {
  const [context, updateContext] = useContext(F1Context);

  return (
    <PageContainer>
      <HeaderContainer>{context.detailViewKey.split('__')[0]}'s</HeaderContainer>
      <HeaderContainer>2024 Predictions</HeaderContainer>
      <ScrollableView>
        <CollapseView text="Team Rankings">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            {context.groupPredictions[context.detailViewKey]?.teamRankings.map(
              (ranking, index) => (
                <RankingEntry key={index}>
                  {index + 1}. {ranking}
                </RankingEntry>
              )
            )}
          </div>
        </CollapseView>
        <CollapseView text="Driver Rankings">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            {context.groupPredictions[context.detailViewKey].driverRankings.map(
              (ranking, index) => (
                <RankingEntry key={index}>
                  {index + 1}. {ranking}
                </RankingEntry>
              )
            )}
          </div>
        </CollapseView>
        <CollapseView text="Race Predictions">
          <RankingEntry>
            Number of wet Races:{" "}
            {
              context.groupPredictions[context.detailViewKey].racePredictions
                .numberOfWetRaces
            }
          </RankingEntry>
          <RankingEntry>
            Race with most DNFs:{" "}
            {
              context.groupPredictions[context.detailViewKey].racePredictions
                .raceWithMostDNF
            }
          </RankingEntry>
        </CollapseView>
        <CollapseView text="Driver Predictions">
            <RankingEntry>
                Driver with Most Overtakes:{" "}
                {context.groupPredictions[context.detailViewKey].driverPredictions.driverWithMostOvertakes}
            </RankingEntry>
            <RankingEntry>
                Number of Overtakes:{" "}
                {context.groupPredictions[context.detailViewKey].driverPredictions.totalNumberOfOvertakes}
            </RankingEntry>
            <RankingEntry>
                Rookie of the Year:{" "}
                {context.groupPredictions[context.detailViewKey].driverPredictions.rookieOfTheYear}
            </RankingEntry>
            <RankingEntry>
                Most "Driver of the Day" Wins:{" "}
                {context.groupPredictions[context.detailViewKey].driverPredictions.mostDriverOfTheDayWins}
            </RankingEntry>
            <RankingEntry>
                Drivers Not on the Grid in 2024:{" "}
                {context.groupPredictions[context.detailViewKey].driverPredictions.driversNotOnTheGrid}
            </RankingEntry>
            <RankingEntry>
                Driver with the Most Crash Damage:{" "}
                {context.groupPredictions[context.detailViewKey].driverPredictions.driverWithMostCrashDamage}
            </RankingEntry>
            <RankingEntry>
                Crash Damage Cost:{" "}
                {context.groupPredictions[context.detailViewKey].driverPredictions.crashDamageCost}
            </RankingEntry>
        </CollapseView>
        <CollapseView text="Bold Predictions">
          <RankingEntry>
            {context.groupPredictions[context.detailViewKey].boldPredictions}
          </RankingEntry>
        </CollapseView>
        <CollapseView text="Wagers">
          <RankingEntry>
            {context.groupPredictions[context.detailViewKey].wagers}
          </RankingEntry>
        </CollapseView>
      </ScrollableView>
      <Nav />
    </PageContainer>
  );
};
