import styled from "styled-components";
import { colors } from "../utils/colors";

export const Button = styled.button`
  margin-top: 21px;
  width: 300px;
  min-height: 40px;
  border: 0px;
  border-radius: 2px;
  color: ${colors.white};
  font-family: "Formula1";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
