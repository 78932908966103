import React from "react";
import styled from "styled-components";
import { colors } from "../utils/colors";

const ButtonContainer = styled.button`
  color: ${colors.primaryRed};
  backgroundcolor: ${colors.white};
  border: 1px solid ${colors.primaryRed};
  border-radius: 2px;
  width: 300px;
  min-height: 40px;
  margin-top: 55px;
  font-family: "Formula1";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

export const SubmitButton = ({ updateModal }) => {
  return (
    <>
      <ButtonContainer
        onClick={() => {
          updateModal(true);
        }}
      >
        Submit
      </ButtonContainer>
    </>
  );
};
