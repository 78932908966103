import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/button";
import { Nav } from "../components/nav";
import { F1Context } from "../context";
import { colors } from "../utils/colors";
import { PageContainer } from "../components/pageContainer";
import { saturateContext } from "../utils/fetchFromDynamo";

export const HomePage = () => {
  const [context, updateContext] = useContext(F1Context);
  const navigate = useNavigate();

  useEffect(() => {
    if (context.fetchFromDynamoStatus === "") {
      saturateContext(context, updateContext);
    }
  }, [])

  useEffect(() => {
    if(!context.user) {
      navigate('/')
    }
  }, [context.user, navigate])

  return (
    <PageContainer>
      {context.user}
      <Button
        style={{ backgroundColor: colors.primaryRed, marginTop: "88px" }}
        onClick={() => navigate("/predictions")}
      >
        Start Predictions
      </Button>
      <Nav />
    </PageContainer>
  );
};
