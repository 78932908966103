import React, { useContext } from "react";
import { Nav } from "../components/nav";
import { PageContainer } from "../components/pageContainer";
import { F1Context } from "../context";
import { PredictionRow } from "../components/predictionRow";
import { PredictionInput } from "../components/predictionInput";
import { RaceSelector } from "../components/raceSelector";
import { SaveButton } from "../components/saveButton";

export const RacePredictionsPage = () => {
  const [context, updateContext] = useContext(F1Context);

  return (
    <PageContainer>
      <div style={{ width: "340px", textAlign: "left", marginBottom: "10px" }}>
        Race Predictions
      </div>
      <div style={{ overflow: "scroll", height: "100%" }}>
        <PredictionRow>
          Number of Wet Races
          <PredictionInput
            type="number"
            placeholder="Enter a number"
            value={context.racePredictions.numberOfWetRaces}
            onChange={(e) =>
              updateContext({
                type: "update-number-of-wet-races",
                races: e.target.value,
              })
            }
          />
        </PredictionRow>
        <PredictionRow>
            Race with most DNFs
            <RaceSelector
                selection={context.racePredictions.raceWithMostDNF}
                setSelection={(value) =>
                  updateContext({
                    type: "update-race-with-most-DNF",
                    race: value,
                  })
                }
            />
        </PredictionRow>
        <SaveButton />
      </div>
      <Nav />
    </PageContainer>
  );
};
