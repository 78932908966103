import React from "react";
import { Nav } from "../components/nav";
import { TeamLogoContainer } from "../components/teamLogoContainer";
import { Teams } from "../utils/teams";
import { PageContainer } from "../components/pageContainer";

export const TeamsPage = () => {
  return (
    <PageContainer>
      <div style={{ marginBottom: "10px" }}>F1 Teams</div>
      <div style={{ overflowY: "scroll", paddingTop: 20 }}>
        {Teams.map((team) => (
          <TeamLogoContainer
            key={team.name}
            name={team.name}
            drivers={team.drivers}
            color={team.color}
            logo={team.logo}
          />
        ))}
      </div>

      <Nav />
    </PageContainer>
  );
};
