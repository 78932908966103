import styled from "styled-components";
import { colors } from "../utils/colors";

export const PageContainer = styled.div`
  display: flex;
  height: 100%;
  color: ${colors.white};
  flex-direction: column;
  font-family: Formula1;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  padding-top: 45px;
  align-items: center;
  overflow: hidden;
`;
