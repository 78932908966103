import React, { useContext } from "react";
import { PredictionRow } from "../components/predictionRow";
import { PageContainer } from "../components/pageContainer";
import { Nav } from "../components/nav";
import { DriverSelector } from "../components/driverSelector";
import { PredictionInput } from "../components/predictionInput";
import { F1Context } from "../context";
import { Rookies } from "../utils/drivers";
import { SaveButton } from "../components/saveButton";

export const DriverPredictionsPage = () => {
  const [context, updateContext] = useContext(F1Context);
  return (
    <PageContainer>
      <div style={{ width: "340px", textAlign: "left", marginBottom: "10px" }}>
        Driver Predictions
      </div>
      <div style={{ overflow: "scroll", height: "100%" }}>
        <PredictionRow>
          Driver with Most Overtakes
          <DriverSelector
            selection={context.driverPredictions.driverWithMostOvertakes}
            setSelection={(value) => {
              updateContext({
                type: "update-driver-with-overtakes",
                driver: value,
              });
            }}
          />
        </PredictionRow>
        <PredictionRow>
          Driver's Total Overtakes
          <PredictionInput
            type="number"
            placeholder="Enter a number"
            value={context.driverPredictions.totalNumberOfOvertakes}
            onChange={(e) =>
              updateContext({
                type: "update-number-overtakes",
                overtakes: e.target.value,
              })
            }
          />
        </PredictionRow>
        {Rookies.length > 0 ? (
          <PredictionRow>
            Rookie of the Year
            <DriverSelector
              selection={context.driverPredictions.rookieOfTheYear}
              setSelection={(value) =>
                updateContext({
                  type: "update-rookie-of-the-year",
                  driver: value,
                })
              }
              drivers={Rookies}
            />
          </PredictionRow>
        ) : (
          <></>
        )}
        <PredictionRow>
          Most "Driver of the Day" Wins
          <DriverSelector
            selection={context.driverPredictions.mostDriverOfTheDayWins}
            setSelection={(value) =>
              updateContext({
                type: "update-driver-of-the-day",
                driver: value,
              })
            }
          />
        </PredictionRow>
        <PredictionRow>
          Drivers not on the grid in 2025
          <PredictionInput
            type="number"
            placeholder="Enter a number"
            value={context.driverPredictions.driversNotOnTheGrid}
            onChange={(e) =>
              updateContext({
                type: "update-drivers-leaving",
                drivers: e.target.value,
              })
            }
          />
        </PredictionRow>
        <PredictionRow>
          Driver with Most Crash Damage
          <DriverSelector
            selection={context.driverPredictions.driverWithMostCrashDamage}
            setSelection={(value) =>
              updateContext({
                type: "update-driver-with-most-crash-damage",
                driver: value,
              })
            }
          />
        </PredictionRow>
        <PredictionRow>
          Crash Damage Cost
          <PredictionInput
            type="number"
            placeholder="Enter a number"
            value={context.driverPredictions.crashDamageCost}
            onChange={(e) =>
              updateContext({
                type: "update-crash-damage-cost",
                cost: e.target.value,
              })
            }
          />
        </PredictionRow>
        <SaveButton />
        <div style={{ height: "10px" }} />
      </div>
      <Nav />
    </PageContainer>
  );
};
