import React from "react";
import { colors } from "../utils/colors";

export const TeamLogoContainer = ({ name, drivers, logo, color }) => {
  return (
    <div
      style={{
        width: 340,
        minHeight: 120,
        color: colors.white,
        border: `1px solid ${color}`,
        borderRadius: "4px",
        display: "flex",
        marginTop: 12,
        marginBottom: 12,
      }}
    >
      <div style={{ height: 50, width: 50, margin: 10 }}>
        <img style={{ height: 50, width: 50 }} src={logo} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <div
          style={{
            fontFamily: "Formula1",
            fontSize: 24,
            lineHeight: "29px",
            marginTop: 14,
            marginBottom: 14,
          }}
        >
          {name}
        </div>
        {drivers.map((driver) => (
          <div
            key={driver.name}
            style={{
              fontFamily: "Titillium Web",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {driver.name} #{driver.number}
          </div>
        ))}
      </div>
    </div>
  );
};
