import styled from "styled-components";
import { colors } from "../utils/colors";

export const TextInput = styled.textarea`
  margin-top: 24px;
  margin-bottom: 12px;
  width: 340px;
  max-height: 420px;
  flex-grow: 1;
  border: 1px solid ${colors.primaryRed};
  border-radius: 4px;
  font-family: "Formula1";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;