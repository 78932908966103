export const colors = {
  primaryRed: "#E10600",
  primaryRedDark: "#920D0A",
  white: "#FFFFFF",
  black: "#000000",
  gray: "#B5B5B5",
  redBullBlue: "#1E5BC6",
  ferrariRed: "#ED1C24",
  mercedesGreen: "#6CD3BF",
  alpineBlue: "#2293D1",
  mcLarenOrange: "#F58020",
  alfaRomeoRed: "#B12039",
  astonMartinGreen: "#2D826D",
  haasGray: "#B6BABD",
  alphaTauriBlue: "#4E7C9B",
  williamsBlue: "#37BEDD",
  kickSauberGreen: "#00E701",
  racingBullsBlue: "#5e8faa",
};
