import React from "react";
import styled from "styled-components";
import { colors } from "../utils/colors";
import { ReactComponent as HomeIconRed } from "../icons/homeIconRed.svg";
import { ReactComponent as PredictionsIconRed } from "../icons/predictionsIconRed.svg";
import { ReactComponent as GroupIconRed } from "../icons/groupIconRed.svg";
import { ReactComponent as TeamsIconRed } from "../icons/teamsIconRed.svg";
import { ReactComponent as HomeIconWhite } from "../icons/homeIconWhite.svg";
import { ReactComponent as PredictionsIconWhite } from "../icons/predictionsIconWhite.svg";
import { ReactComponent as GroupIconWhite } from "../icons/groupIconWhite.svg";
import { ReactComponent as TeamsIconWhite } from "../icons/teamsIconWhite.svg";
import { useNavigate } from "react-router-dom";

const NavContainer = styled.div`
  margin-top: auto;
  border-top: 2px solid ${colors.primaryRed};
  background-color: ${colors.black};
  width: 100vw;
  min-height: 60px;
  display: flex;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-height: 60px;
`;

export const Nav = () => {
  const url = window.location.pathname
  const navigate = useNavigate();

  return (
    <NavContainer>
      <IconContainer
        style={{
          backgroundColor:
            url === "/home" ? colors.primaryRed : colors.black,
        }}
        onClick={() => {
          navigate("/home");
        }}
      >
        {url === "/home" ? <HomeIconWhite /> : <HomeIconRed />}
      </IconContainer>
      <IconContainer
        style={{
          backgroundColor:
            url.includes("/predictions") ? colors.primaryRed : colors.black,
        }}
        onClick={() => {
          navigate("/predictions");
        }}
      >
        {url.includes("/predictions") ? (
          <PredictionsIconWhite />
        ) : (
          <PredictionsIconRed />
        )}
      </IconContainer>
      <IconContainer
        style={{
          backgroundColor:
            url.includes("/group") ? colors.primaryRed : colors.black,
        }}
        onClick={() => {
          navigate("/group");
        }}
      >
        {url.includes("/group") ? <GroupIconWhite /> : <GroupIconRed />}
      </IconContainer>
      <IconContainer
        style={{
          backgroundColor:
            url === "/team" ? colors.primaryRed : colors.black,
        }}
        onClick={() => {
          navigate("/team");
        }}
      >
        {url === "/team" ? <TeamsIconWhite /> : <TeamsIconRed />}
      </IconContainer>
    </NavContainer>
  );
};
