import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { F1Context } from "../context";
import { colors } from "../utils/colors";
import { Button } from "../components/button";

const LoginContainer = styled.div`
  background-color: ${colors.black};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FakeLogo = styled.div`
  margin: 65px 135px;
  height: 120px;
  width: 120px;
  background-color: ${colors.white};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameLabel = styled.div`
  font-family: "Formula1";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${colors.white};
  text-align: left;
`;

const NameInput = styled.input`
  margin-top: 10px;
  width: 300px;
  height: 40px;
  border: 1px solid #e10600;
  border-radius: 2px;
  font-family: "Formula1";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const LoginPage = () => {
  const [context, updateContext] = useContext(F1Context);
  const [inputValue, setValue] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (context.user) {
        navigate('/home')
    }
  }, [context.user, navigate])

  return (
    <LoginContainer>
      <FakeLogo />
      <InputContainer>
        <NameLabel>Enter your name</NameLabel>
        <NameInput
          value={inputValue}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <Button
          style={{
            backgroundColor: inputValue ? colors.primaryRed : colors.gray,
          }}
          onClick={() => {
            updateContext({ type: "update-user", user: inputValue });
            navigate("/home");
          }}
        >
          Go
        </Button>
      </InputContainer>
    </LoginContainer>
  );
};
