import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/button";
import { Nav } from "../components/nav";
import { F1Context } from "../context";
import { colors } from "../utils/colors";
import { PageContainer } from "../components/pageContainer";
import { ReactComponent as DoubleChevron } from "../icons/doubleChevronRight.svg";
import { SubmitButton } from "../components/submitButton";
import { saturateContext } from "../utils/fetchFromDynamo";
import { AnimatePresence } from "framer-motion";
import { Modal } from "../components/modal";

const PredictionsButton = ({ text, path }) => {
  const navigate = useNavigate();
  return (
    <Button
      style={{
        backgroundColor: colors.primaryRed,
        paddingLeft: 13,
        border: `1px solid ${colors.white} `,
      }}
      onClick={() => navigate(`/predictions/${path}`)}
    >
      {text}
      <DoubleChevron style={{ marginLeft: "auto" }} />
    </Button>
  );
};

export const PredictionsPage = () => {
  const [context, updateContext] = useContext(F1Context);
  const [modalState, updateModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (context.fetchFromDynamoStatus === "") {
      saturateContext(context, updateContext);
    }
  }, []);

  useEffect(() => {
    if (!context.user) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <PageContainer>
        <div style={{overflowY: 'auto', marginBottom: 15}}>
          <div style={{ marginBottom: 15 }}>Set Predictions</div>
          <PredictionsButton text="Team Rankings" path="team-rankings" />
          <PredictionsButton text="Driver Rankings" path="driver-rankings" />
          <PredictionsButton text="Race Predictions" path="race-predictions" />
          <PredictionsButton
            text="Driver Predictions"
            path="driver-predictions"
          />
          <PredictionsButton text="Bold Predictions" path="bold-predictions" />
          <PredictionsButton text="Wagers" path="wagers" />
          <SubmitButton updateModal={updateModal} />
        </div>
        <Nav />
      </PageContainer>
      <AnimatePresence
        // Disable any initial animations on children that
        // are present when the component is first rendered
        initial={false}
        // Only render one component at a time.
        // The exiting component will finish its exit
        // animation before entering component is rendered
        exitBeforeEnter={true}
        // Fires when all exiting nodes have completed animating out
        onExitComplete={() => null}
      >
        {modalState && (
          <Modal
            handleClose={() => updateModal(false)}
            context={context}
          />
        )}
      </AnimatePresence>
    </>
  );
};
