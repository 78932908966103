import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../utils/colors";
import { ReactComponent as ChevronDown } from "../icons/chevronDown.svg";

const CollapseContainer = styled.div`
  height: 20px;
  width: 330px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${colors.primaryRed};
  font-family: "Formula1";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const CollapseView = ({ text, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <CollapseContainer onClick={() => setOpen(!open)}>
        {text}
        <div style={{ marginLeft: "auto" }}>
          <ChevronDown
            style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
          />
        </div>
      </CollapseContainer>
      {open ? <>{children}</> : null}
    </>
  );
};
