import styled from "styled-components";

export const TextContainer = styled.div`
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  width: 340px;
  text-align: left;
  margin-bottom: 18px;
`;