import styled from "styled-components";
import { colors } from "../utils/colors";

export const PredictionInput = styled.input`
  margin-top: 10px;
  width: 300px;
  min-height: 40px;
  border: 1px solid ${colors.primaryRedDark};
  border-radius: 2px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  line-height: 24px;
`;