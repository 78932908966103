import React, { useContext, useEffect } from "react";
import { Nav } from "../components/nav";
import { PageContainer } from "../components/pageContainer";
import { colors } from "../utils/colors";
import { ReactComponent as DoubleChevron } from "../icons/doubleChevronRight.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/button";
import { F1Context } from "../context";
import { saturateContext } from "../utils/fetchFromDynamo";

const GroupPredictionsButton = ({ detailKey, updateContext }) => {
  const navigate = useNavigate();
  return (
    <Button
      style={{
        backgroundColor: colors.black,
        paddingLeft: 13,
        border: `1px solid ${colors.primaryRed} `,
      }}
      onClick={() => {
        updateContext({ type: "update-detail-key", key: detailKey })
        navigate(`/group/detail`)
      }}
    >
      {detailKey.split('__')[0]}'s Predictions
      <DoubleChevron style={{ marginLeft: "auto" }} />
    </Button>
  );
};

export const GroupPage = () => {
  const [context, updateContext] = useContext(F1Context);

  useEffect(() => {
    if (context.fetchFromDynamoStatus === "") {
      saturateContext(context, updateContext);
    }
  }, [])

  return (
    <PageContainer>
      <div style={{ marginBottom: 15 }}>2024 Season</div>
      {Object.keys(context.groupPredictions).map((key) => (
        <GroupPredictionsButton key={key} detailKey={key} updateContext={updateContext}/>
      ))}
      <Nav />
    </PageContainer>
  );
};
