import React, { useContext } from "react";
import { TextContainer } from "../components/textContainer";
import { PageContainer } from "../components/pageContainer";
import { TextInput } from "../components/textInput";
import { Nav } from "../components/nav";
import { F1Context } from "../context";
import { SaveButton } from "../components/saveButton";

export const WagersPage = () => {
  const [context, updateContext] = useContext(F1Context);
  return (
    <PageContainer>
      <div style={{ width: "340px", textAlign: "left", marginBottom: "10px" }}>
        Wagers
      </div>
      <TextContainer>Optional one-on-one or mixed group wagers</TextContainer>
      <TextInput
        value={context.wagers}
        onChange={(e) => {
          updateContext({ type: "update-wagers", wagers: e.target.value });
        }}
      />
      <SaveButton />
      <Nav />
    </PageContainer>
  );
};
