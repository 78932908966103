import React, { useReducer, createContext } from "react";
import { Drivers } from "./utils/drivers";
import { Teams } from "./utils/teams";

const initialData = {
  user: localStorage.getItem("f1User"),
  detailViewKey: "",
  fetchFromDynamoStatus: "",
  groupPredictions: {},
  teamRankings: Teams.map(team => team.name),
  driverRankings: Drivers.map((driver) => `${driver.name} - ${driver.team}`),
  racePredictions: {
    numberOfWetRaces: "",
    raceWithMostDNF: "",
  },
  driverPredictions: {
    driverWithMostOvertakes: "",
    totalNumberOfOvertakes: "",
    rookieOfTheYear: "",
    mostDriverOfTheDayWins: "",
    driversNotOnTheGrid: "",
    driverWithMostCrashDamage: "",
    crashDamageCost: "",
  },
  boldPredictions: "",
  wagers: "",
};

export const F1Context = createContext([initialData, () => {}]);

function f1Reducer(state, action) {
  switch (action.type) {
    case "update-detail-key": {
      return { ...state, detailViewKey: action.key }
    }
    case "update-fetch-status": {
      return {
        ...state,
        fetchFromDynamoStatus: action.fetchStatus,
      }
    }
    case "update-from-dynamo": {
      const withOtherUsers = {
        ...state,
        fetchFromDynamoStatus: "Complete",
        groupPredictions: action.otherUsers,
      };

      if (action.currentUser) {
        return {
          ...withOtherUsers,
          teamRankings: action.currentUser.teamRankings,
          driverRankings: action.currentUser.driverRankings,
          racePredictions: action.currentUser.racePredictions,
          driverPredictions: action.currentUser.driverPredictions,
          boldPredictions: action.currentUser.boldPredictions,
          wagers: action.currentUser.wagers,
        };
      }
      return withOtherUsers;
    }
    case "update-team-rankings": {
      return { ...state, teamRankings: action.rankings };
    }
    case "update-driver-rankings": {
      return { ...state, driverRankings: action.rankings };
    }
    case "update-race-with-most-DNF": {
      return {
        ...state,
        racePredictions: {
          ...state.racePredictions,
          raceWithMostDNF: action.race,
        },
      };
    }
    case "update-number-of-wet-races": {
      return {
        ...state,
        racePredictions: {
          ...state.racePredictions,
          numberOfWetRaces: action.races,
        },
      };
    }
    case "update-driver-with-overtakes": {
      return {
        ...state,
        driverPredictions: {
          ...state.driverPredictions,
          driverWithMostOvertakes: action.driver,
        },
      };
    }
    case "update-number-overtakes": {
      return {
        ...state,
        driverPredictions: {
          ...state.driverPredictions,
          totalNumberOfOvertakes: action.overtakes,
        },
      };
    }
    case "update-rookie-of-the-year": {
      return {
        ...state,
        driverPredictions: {
          ...state.driverPredictions,
          rookieOfTheYear: action.driver,
        },
      };
    }
    case "update-driver-of-the-day": {
      return {
        ...state,
        driverPredictions: {
          ...state.driverPredictions,
          mostDriverOfTheDayWins: action.driver,
        },
      };
    }
    case "update-drivers-leaving": {
      return {
        ...state,
        driverPredictions: {
          ...state.driverPredictions,
          driversNotOnTheGrid: action.drivers,
        },
      };
    }
    case "update-driver-with-most-crash-damage": {
      return {
        ...state,
        driverPredictions: {
          ...state.driverPredictions,
          driverWithMostCrashDamage: action.driver,
        },
      };
    }
    case "update-crash-damage-cost": {
      return {
        ...state,
        driverPredictions: {
          ...state.driverPredictions,
          crashDamageCost: action.cost,
        },
      };
    }
    case "update-bold-predictions": {
      return { ...state, boldPredictions: action.boldPredictions };
    }
    case "update-wagers": {
      return { ...state, wagers: action.wagers };
    }
    case "update-user": {
      localStorage.setItem("f1User", action.user);
      return { ...state, user: action.user };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function ContextProvider({ children }) {
  const [state, dispatch] = useReducer(f1Reducer, initialData);
  return (
    <F1Context.Provider value={[state, dispatch]}>
      {children}
    </F1Context.Provider>
  );
}
