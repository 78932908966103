export const fetchFromDynamo = async () => {
  const result = await fetch(
    "https://njz4z9rn49.execute-api.us-east-1.amazonaws.com/prediction?year=2024&groupId=1"
  ).then((response) => response.json());

  console.log(result);
  return Object.fromEntries(
    result.map((entry) => [entry.id, JSON.parse(entry.data)])
  );
};

export const saturateContext = async (context, updateContext) => {
  updateContext({
    type: "update-fetch-status",
    fetchStatus: "Pending",
  });

  const data = await fetchFromDynamo();
  const currentUser = data[context.user];
  delete data[context.user];

  updateContext({
    type: "update-from-dynamo",
    currentUser,
    otherUsers: data,
  });
};
