import React from "react";
import { motion } from "framer-motion";
import { pushToDynamo } from "../utils/pushToDynamo";
import { colors } from "../utils/colors";

const Backdrop = ({ children, onClick }) => {
  return (
    <motion.div
      onClick={onClick}
      className="backdrop"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
};

const dropIn = {
  hidden: {
    y: "100vh",
    opacity: 0,
  },
  visible: {
    y: "0vh",
    opacity: 1,
    transition: "linear",
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

export const Modal = ({ handleClose, context }) => {
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div style={{ color: colors.white, width: 330 }}>
          <div
            style={{ fontFamily: "Formula1", fontSize: 24, marginBottom: 12 }}
          >
            Are you sure?
          </div>
          <div
            style={{
              fontFamily: "Titillium Web",
              fontSize: 16,
              marginBottom: 12,
            }}
          >
            Submitting will lock in your predictions. You will not be able to
            make any changes.
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                width: 170,
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 3,
                backgroundColor: colors.white,
                color: colors.primaryRed,
                fontFamily: "Formula1",
                fontSize: 16,
                lineHeight: "19px",
                border: `1px solid ${colors.primaryRed}`,
                borderRadius: "2px",
              }}
              onClick={() => handleClose()}
            >
              Oh S***! Go back
            </div>
            <div
              style={{
                width: 170,
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 3,
                backgroundColor: colors.primaryRed,
                fontFamily: "Formula1",
                fontSize: 16,
                lineHeight: "19px",
                border: `1px solid ${colors.primaryRed}`,
                borderRadius: "2px",
              }}
              onClick={() => {
                pushToDynamo(context);
                handleClose();
              }}
            >
              Yes, Submit
            </div>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};
