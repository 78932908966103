import React from 'react';
import styled from 'styled-components';
import { colors } from '../utils/colors';
import { Drivers } from '../utils/drivers';

const StyledSelect = styled.select`
    width: 300px;
    min-height: 40px;
    border: 1px solid ${colors.primaryRedDark};
    border-radius: 2px;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
`;

export const DriverSelector = ({ selection, setSelection, multiple=false, drivers=Drivers }) => {
    return (
        <div>
            <StyledSelect placeholder="Select Driver" value={selection} onChange={(e) => {setSelection(e.target.value)}} multiple={multiple} >
                <option value="" disabled defaultValue hidden>Select Driver</option>
                {drivers.map((driver) => (
                    <option key={driver.number} value={driver.name}>{`${driver.name} - #${driver.number} | ${driver.team}`}</option>
                ))}
            </StyledSelect>
        </div>
    );
}